import {Component, EventEmitter, Input, OnInit, Output} from "@angular/core";
import {
    MatCard,
    MatCardAvatar,
    MatCardContent,
    MatCardHeader,
    MatCardSubtitle,
    MatCardTitle
} from "@angular/material/card";
import {DatePipe, NgClass, NgIf} from "@angular/common";
import {TranslateModule, TranslateService} from "@ngx-translate/core";
import {GetAssetService} from "../../../services/get-asset.service";


@Component({
    selector: 'ogp-card-selection',
    templateUrl: './ogp-card-selection.component.html',
    styleUrl: './ogp-card-selection.component.scss',
    standalone: true,
    imports: [
        MatCard,
        MatCardHeader,
        MatCardTitle,
        MatCardSubtitle,
        NgIf,
        TranslateModule,
        MatCardAvatar,
        NgClass,
        MatCardContent,
        DatePipe
    ]
})
export class OgpCardSelectionComponent implements OnInit {
    @Input({required: true}) public cardOption!: CardOption;
    @Output() public cardSelected: EventEmitter<any> = new EventEmitter<any>(); // Event for card selection
    public cardId: string | null = null;
    public cardTitleKey: string | null = null;
    public cardInformationKey: string | null = null;
    public dateLabel: string | null = null;
    public date: string | null = null;
    public iconPath: string | null = null;
    public headerIconAlt?: string;
    public hoverClass: string | null = null;
    public backgroundColor: string | null = null;
    public marginClass: string | null = null;
    public tabindex!: string;

    constructor(private readonly getAssetService: GetAssetService,
                private readonly translateService: TranslateService) {
    }

    public ngOnInit(): void {
        // do not change "chard-selection" as ID-Prefix
        if (this.cardOption?.cardId != null) {
            this.cardId = "card-selection-" + this.cardOption.cardId.replace('_', '-');
        } else {
            this.cardId = "card-selection-" + (this.cardOption?.cardTitleKey?.replace(' ', '-') ?? "no-value");
        }
        if (this.cardOption?.dateLabel != null) {
            this.dateLabel = this.cardOption?.dateLabel;
            this.date = this.cardOption?.date ?? null
        }
        this.cardTitleKey = this.cardOption?.cardTitleKey ?? null;
        this.cardInformationKey = this.cardOption?.cardInformationKey ?? null;
        if (this.cardOption?.headerIconNameKey != null) {
            this.translateService.get(this.cardOption.headerIconNameKey).subscribe(translatedValue => {
                this.iconPath = this.getAssetService.getIconVolitionPath(translatedValue);
            });
        }
        this.hoverClass = this.cardOption?.cardHoverColor != null
        && this.cardOption?.cardHoverColor !== "none"
            ? `hover-${this.cardOption.cardHoverColor}`
            : "";
        this.backgroundColor = this.cardOption?.cardBackgroundColor != null
        && this.cardOption?.cardBackgroundColor !== "none"
            ? `bg-${this.cardOption.cardBackgroundColor}`
            : "";
        this.marginClass = this.cardOption?.marginClass ?? "margin-t-m";
        this.tabindex = this.cardOption.tabbable === false
            ? "-1"
            : "0"
    }

    public onCardClick(): void {
        this.cardSelected.emit(this.cardOption.optionKey); // Emit the key when the card is clicked
    }

    public onCardEnterKeyPressed(event: KeyboardEvent): void {
        if (event.key === 'Enter') {
            this.cardSelected.emit(this.cardOption.optionKey); // Emit the key when the card is clicked
        }
    }
}

export type HoverColor = "light-orange" | "light-blue" | "light-green" | "neutral" | "none";
export type BackgroundColor = "light-orange" | "light-blue" | "light-green" | "none";

export interface CardOption {
    cardId?: string,
    cardTitleKey?: string,
    cardInformationKey?: string,
    dateLabel?: string,
    date?: string,
    headerIconNameKey?: string,
    cardHoverColor?: HoverColor,
    cardBackgroundColor?: BackgroundColor,
    headerIconAlt?: string,
    optionKey?: string,
    marginClass?: string,
    tabbable?: boolean
}