import {Component, EventEmitter, OnInit, Output} from "@angular/core";
import {
    getDonorOptionBackgroundColor,
    getDonorOptionHoverColor,
    NavigationStepData,
    OrganAndTissueDonationEntry,
    VolitionService
} from "../../volition.service";
import {TranslateModule} from "@ngx-translate/core";
import {OgpCardContentComponent} from "../../../shared/components/ogp-card-content/ogp-card-content.component";
import {MatDivider} from "@angular/material/divider";
import {
    CardOption,
    OgpCardSelectionComponent
} from "../../../shared/components/ogp-card-selection/ogp-card-selection.component";
import {MatButton} from "@angular/material/button";
import {ObButtonDirective} from "@oblique/oblique";
import {DatePipe, NgIf} from "@angular/common";
import {MatTooltip} from "@angular/material/tooltip";

@Component({
    selector: "ogp-view-template-step",
    templateUrl: "view-template-step.component.html",
    styleUrls: ['./view-template-step.component.scss'],
    standalone: true,
    imports: [
        TranslateModule,
        OgpCardContentComponent,
        MatDivider,
        OgpCardSelectionComponent,
        MatButton,
        ObButtonDirective,
        NgIf,
        DatePipe,
        MatTooltip
    ]
})

export class ViewTemplateStepComponent implements OnInit {
    @Output() public activeStepChanged: EventEmitter<NavigationStepData> = new EventEmitter<NavigationStepData>();
    //Variables for Frontend
    public creationDate!: string | null;
    public fullName?: string | null;
    public birthDay?: string | null;
    public homeTown?: string | null;
    public placeOfBirth?: string | null;
    public eMail?: string | null;
    public mobileNumber?: string | null;
    public telephoneNumber?: string | null;
    public acceptedLegalText?: boolean;
    protected donorCardOption!: CardOption | null;
    private donationData!: OrganAndTissueDonationEntry;


    constructor(private volitionService: VolitionService) {
    }

    public ngOnInit(): void {
        this.donationData = this.volitionService.getDonationDataValue()
        const selectedDecision = this.donationData.volition?.decision;
        this.donorCardOption = selectedDecision != null
            ? {
                cardId: selectedDecision ?? "volitionDecision",
                cardTitleKey: `volitions.decisionStep.${selectedDecision}.title`,
                cardInformationKey: `volitions.decisionStep.${selectedDecision}.information`,
                headerIconNameKey: `volitions.decisionStep.${selectedDecision}.headerIconName`,
                headerIconAlt: `volitions.decisionStep.${selectedDecision}.headerIconAlt`,
                optionKey: selectedDecision,
                cardHoverColor: getDonorOptionHoverColor(selectedDecision),
                cardBackgroundColor: getDonorOptionBackgroundColor(selectedDecision),
                marginClass: "margin-0",
                tabbable: false
            }
            : null
        this.creationDate = this.donationData.volition?.creationDate ?? new Date().toString();
        this.fullName = this.donationData.person?.lastName + ", " + this.donationData.person?.firstName;
        this.birthDay = this.donationData.person?.birthDate ?? null;
        this.homeTown = this.donationData.person?.homeTown ?? null;
        this.placeOfBirth = this.donationData.person?.placeOfBirth ?? null;
        this.eMail = this.donationData.person?.email ?? null;
        this.mobileNumber = this.donationData.person?.mobileNumber?.length ?? 0 < 4 ? null : this.donationData.person?.mobileNumber;
        this.telephoneNumber = this.donationData.person?.telephoneNumber?.length ?? 0 < 4 ? null : this.donationData.person?.telephoneNumber;
        this.acceptedLegalText = this.donationData.person?.acceptedLegalText ?? false;
    }

    public onEditVolitionDetail(): void {
        if (this.donationData.volition?.decision != null) {
            this.volitionService.setDonationVolitionData("lastCompletedMainStep", null);
            this.volitionService.setDonationVolitionData("lastCompletedStep", "donorOptionStep");
            this.volitionService.setDonationVolitionData("decision", this.donationData.volition?.decision);
            this.activeStepChanged.emit({
                activeStep: "DECISION_STEP",
                activeSubStep: "myChoiceStep",
                lastCompletedStep: "donorOptionStep"
            });
        } else {
            throw new Error('could not set decision properly');
        }
    }

    //TRIGGER THE NEXT STEP
    public confirmAndSubmit(): void {
        if (this.donationData.volition?.decision != null) {
            this.volitionService.setDonationVolitionData("lastCompletedMainStep", "CONFIRMATION_STEP");
            this.volitionService.setDonationVolitionData("lastCompletedStep", "viewTemplateStep");
            this.volitionService.setDonationVolitionData("creationDate", this.creationDate);
            this.volitionService.saveVolitionDto().subscribe({
                next: () => {
                    console.log('Volition saved successfully.');
                },
                error: (err) => {
                    console.error('Error in saving volition:', err);
                },
            });
            this.activeStepChanged.emit({
                activeStep: "STORAGE_STEP",
                activeSubStep: "viewConfirmationStep",
                lastCompletedStep: "viewTemplateStep",
                action: "created"
            });
        }
    }
}