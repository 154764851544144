import {Component, Input} from '@angular/core';
import {CommonModule} from '@angular/common';
import {MatLabel} from '@angular/material/form-field';
import {TranslateModule} from '@ngx-translate/core';
import {PersonDto} from '@ogreg/shared';
import {ObExternalLinkDirective} from '@oblique/oblique';
import {OgpCardContentComponent} from "../../shared/components/ogp-card-content/ogp-card-content.component";

@Component({
    selector: 'ogp-personal-data',
    standalone: true,
    imports: [CommonModule, MatLabel, TranslateModule, ObExternalLinkDirective, OgpCardContentComponent],
    templateUrl: './personal-data.component.html',
    styleUrl: './personal-data.component.scss'
})
export class PersonalDataComponent {
    @Input() eIamPerson!: PersonDto;
}
