<ob-alert class="margin-0" [hasRoleAlert]="true" data-cy="enter-verification-code-alert">
  <mat-card-title [innerHtml]="'personRegister.contactDetails.emailVerification.alertTitle' | translate"
                  data-cy="enter-verification-code-alert-title">
  </mat-card-title>
  <mat-card-content [innerHtml]="'personRegister.contactDetails.emailVerification.alertMessage' | translate"
                    data-cy="enter-verification-code-alert-title-content"></mat-card-content>
</ob-alert>
<form [formGroup]="formGroup" (ngSubmit)="onSubmit()">
  <div class="container">
    <div class="row custom-padding">
      <div class="col-lg-6">
        <mat-form-field appearance="outline">
          <mat-label>{{ "personRegister.contactDetails.emailVerification.enterVerificationCode" | translate }}</mat-label>
          <input matInput type="number" formControlName="code" ogpEmailVerificationCodeValidator data-cy="code-input" />
        </mat-form-field>
      </div>
      <div class="col-lg-6">
        <button type="submit"
                mat-button
                [disabled]="!this.formGroup.dirty"
                obButton="primary"
                data-cy="submit-form-button">
          {{ "confirm" | translate }}
        </button>
      </div>
    </div>
  </div>
</form>
<p>
  <a href="#" (click)="$event.preventDefault(); resendEmail()">
    {{ "personRegister.contactDetails.emailVerification.link1" | translate }}
  </a>
</p>
<p>
  <a href="#"
     (click)="$event.preventDefault(); reset()"
     data-cy="verify-another-email-link">
    {{ "personRegister.contactDetails.emailVerification.link2" | translate }}
  </a>
</p>
