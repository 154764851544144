import {Injectable} from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable({
    providedIn: 'root',
})
export class OgpStepperService {
    private stepSubject = new BehaviorSubject<number>(0); // Initial step is 0
    currentStep$ = this.stepSubject.asObservable();

    public getNextMainStep(delay: number) {
        // TODO: Save the StepData in the Browser here
        setTimeout(() => {
            this.nextStep();
        }, delay);
    }

    // Method to go to the next step
    private nextStep(): void {
        const nextStep = this.stepSubject.value + 1;
        this.stepSubject.next(nextStep);
    }

    // Method to go to the previous step
    public getPreviousMainStep(): void {
        const previousStep = this.stepSubject.value - 1;
        this.stepSubject.next(previousStep);
    }

    public getCurrentMainStep(): number {
        return (this.stepSubject.value);
    }

    public resetStepper(){
        this.setStep(0)
    }

    // Method to set a specific step (e.g., reset or jump)
    private setStep(step: number): void {
        this.stepSubject.next(step);
    }
}
