import {LoginService} from "@ogreg/ogreg-login";
import {HttpClient} from "@angular/common/http";
import {Component} from "@angular/core";
import {FormsModule} from "@angular/forms";
import {MatInput} from "@angular/material/input";
import {AuthService} from "../../services/http-services/auth.service";
import {PersonsService} from "../../services/http-services/persons.service";
import {ObNotificationService} from "@oblique/oblique";
import {firstValueFrom} from "rxjs";
import {environment} from "../../../environments/environment";

@Component({
    selector: "ogp-debug-login",
    templateUrl: "./debug-login.component.html",
    standalone: true,
    imports: [
        FormsModule,
        MatInput
    ],
    styleUrls: ["./debug-login.component.scss"]
})
export class DebugLoginComponent {

    id = 'CH1234567'
    firstName = 'Vorname'
    lastName = 'Nachname'
    birthDate = '1970-01-30'
    nationality = 'CH'
    placeOfBirth = 'Bern'
    homeTown = 'Bern'
    socialSecurityNumber = '756.1234.567'
    gender = 'M'

    ahvn = ''; // AHV number will be generated
    xmlFirstName = '';
    xmlOfficialName = '';
    xmlOriginalName = '';
    xmlSex = '';
    xmlBirthDate = '';
    xmlBirthPlace = '';

    constructor(private loginService: LoginService,
                private readonly authService: AuthService,
                private readonly personService: PersonsService,
                private readonly notificationService: ObNotificationService,
                private readonly http: HttpClient) {
        this.ahvn = this.generateRandomAHV();
    }

    public login() {
        window.sessionStorage.clear();
        window.sessionStorage.setItem("access_token", "eyJhbGciOiJub25lIn0." + btoa(JSON.stringify(
            {
                "sub": this.id,
                "given_name": this.firstName,
                "family_name": this.lastName,
                "birthdate": this.birthDate,
                "nationality": this.nationality,
                "place_of_birth": this.placeOfBirth,
                "home_town": this.homeTown,
                "social_security_number": this.socialSecurityNumber,
                "gender": this.gender
            })) + ".");
        window.sessionStorage.setItem("access_token_stored_at", "1724682022549")
        window.sessionStorage.setItem("expires_at", "1924682022549")
        this.loginService.logIn();
        this.personService.savePerson().subscribe(() => {
            this.authService.exchangeToken().subscribe(token => {
                if (token.token) {
                    const tokenParts = token.token.split('.');
                    if (tokenParts.length !== 3) {
                        this.notificationService.error("Something went wrong")
                        throw new Error('Invalid JWT Token');
                    }
                    window.sessionStorage.setItem("ogregToken", token.token);
                    this.notificationService.success("Successfully logged in")
                }
            })
        });
    }


    public regenerateAhv() {
        this.ahvn = this.generateRandomAHV();
    }

    private generateRandomAHV(): string {
        const prefix = "756"
        //generate 9 random numbers in an array
        const random: number[] = Array.from({length: 9}, () => Math.floor(Math.random() * 10));
        //calculate the checksum
        let checksum = 0;
        for (let i = 11; i >= 0; i--) {
            if (i % 2 === 0) {
                checksum += [7, 5, 6, ...random][i];
            } else {
                checksum += [7, 5, 6, ...random][i] * 3;
            }
        }
        checksum = 10 - (checksum % 10);
        if (checksum === 10) {
            checksum = 0;
        }
        return prefix + random.join('') + checksum;
    }


    private async processXMLTemplate(): Promise<string> {
        const template = await firstValueFrom(this.http.get('assets/debug-user-template.xml', {responseType: 'text'}));

        return template
            .replace('${ahvn}', this.ahvn)
            .replace('${firstname}', this.xmlFirstName)
            .replace('${officialname}', this.xmlOfficialName)
            .replace('${originalname}', this.xmlOriginalName)
            .replace('${sex}', this.xmlSex)
            .replace('${birthdate}', this.xmlBirthDate)
            .replace('${birthplace}', this.xmlBirthPlace);
    }

    // Send XML to backend
    private sendXMLToBackend(xml: string): void {
        this.http.post(environment.debugMutationEndpoint, xml, {
            headers: {'Content-Type': 'application/xml'},
            responseType: "text"
        }).subscribe({
            next: () => this.notificationService.success('XML successfully sent to the backend'),
            error: () => this.notificationService.error('Failed to send XML to the backend')
        });
    }

    public async loginWithXML() {
        try {
            const processedXML = await this.processXMLTemplate();
            this.sendXMLToBackend(processedXML);
        } catch (error) {
            console.error('Error processing XML:', error);
            this.notificationService.error('Failed to process XML');
        }
    }
}