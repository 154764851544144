<mat-vertical-stepper #stepper
                      [selectedIndex]="currentStep"
                      [linear]="true"
                      [class.mat-stepper-lg]="true"
                      [disableRipple]="true">
    <ng-template matStepperIcon="edit">
        <mat-icon svgIcon="pen"/>
    </ng-template>

    <ng-template matStepperIcon="done">
        <mat-icon svgIcon="checkmark"/>
    </ng-template>

    <ng-container *ngFor="let step of steps; let index = index">
        <mat-step label="{{ step }}"></mat-step>
    </ng-container>
</mat-vertical-stepper>
