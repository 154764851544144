<h2>{{"volitions.myChoiceStep.donor.organsAndTissueDialog.title" | translate}}</h2>
<mat-dialog-content class="mat-typography">
    <ng-container *ngFor="let organ of organsAndTissues">
        <h3 class="h3-dialog-title margin-0"><b>{{ organ.title | translate }}</b></h3>
        <p>{{ organ.description | translate }}</p>
    </ng-container>
</mat-dialog-content>
<mat-dialog-actions align="end">
    <button class="margin-t-m" mat-button mat-dialog-close obButton="primary">
        {{"volitions.myChoiceStep.donor.organsAndTissueDialog.closeButtonLabel" | translate}}
    </button>
</mat-dialog-actions>