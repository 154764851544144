<ogp-card-content [cardTitle]="'volitions.viewConfirmationStep.title' | translate"
                  [cardInformation]="'volitions.viewConfirmationStep.information' | translate"
                  [hasButton]="false">
    <ng-container>
        <ogp-card-selection [cardOption]="donorCardOption"
                            class="hover"
                            (cardSelected)="onConfirmationCardEnterKeyPressed($event)">
        </ogp-card-selection>
        <div class="ogp-flex-block ogp-flex-block-align-horizontal-end margin-t-l">
            <button (click)="onOpenVolitionRegistryEntry()"
                    class="nowrap ogp-flex-block-items-row-gap"
                    data-cy="open-volition-registry-entry-btn"
                    mat-button
                    obButton="secondary"
                    type="button">
                {{ "volitions.viewConfirmationStep.openVolitionRegistryEntry" | translate }}
            </button>
            <button (click)="onDownloadPDF()"
                    class="nowrap"
                    data-cy="download-pdf-btn"
                    mat-button
                    obButton="primary"
                    type="button">
                {{ "volitions.viewConfirmationStep.downloadPdf" | translate }}
            </button>
        </div>
    </ng-container>
</ogp-card-content>