import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { EnvironmentService } from '../environment/environment.service';
import { HttpClient } from '@angular/common/http';
import { UpsertEnrichmentDto } from "@ogreg/shared";

@Injectable({
  providedIn: 'root'
})
export class EnrichmentService {
  model = 'enrichment';

  constructor(
    private readonly http: HttpClient,
    private readonly env: EnvironmentService
  ) {}

  saveEnrichment(enrichmentDto: UpsertEnrichmentDto): Observable<void> {
    return this.http.post<void>(
      `${this.env.ogPersonRegisterUrl}${this.model}`,
      enrichmentDto
    );
  }

  updateEnrichment(enrichmentDto: UpsertEnrichmentDto): Observable<void> {
    return this.http.put<void>(
      `${this.env.ogPersonRegisterUrl}${this.model}`,
      enrichmentDto
    );
  }
}
