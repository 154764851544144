<ogp-card-content [cardTitle]="'volitions.myChoiceStep.title' | translate"
                  [hasButton]="true"
                  [buttonLabel]="'volitions.changeDecisionButton'"
                  [buttonClickAction]="navigateToChangeDecisionStep">
<ng-container>
    <ogp-donor-step *ngIf="myActiveChoice === 'DONOR' || myActiveChoice === 'FULL_DONOR' || myActiveChoice === 'PARTIAL_DONOR'"
                    [selectedDonorType]="selectedDonorOption"
                    (donorSelectionChanged)="donorTypeSelected($event)"
    ></ogp-donor-step>
</ng-container>
</ogp-card-content>
<div *ngIf="showStepNavigationButtons"
     class="ogp-flex-block ogp-flex-block-align-horizontal-end margin-t-l">
    <button class="ogp-flex-block-items-row-gap"
            mat-button
            data-cy="next-step-button"
            obButton="tertiary"
            type="button"
            (click)="cancelVolitionProcess()">
        <b>{{ "volitions.stepper.stepperAction.cancel" | translate }}</b>
    </button>
    <button mat-button
            obButton="primary"
            data-cy="cancel-process-button"
            type="button"
            (click)="navigateToNextStep()">
        {{ "volitions.stepper.stepperAction.continue" | translate }}
    </button>
</div>