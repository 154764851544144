import { Injectable } from '@angular/core';
import { Base64 } from 'js-base64';
import { BehaviorSubject } from "rxjs";
import { HttpClient } from "@angular/common/http";
import { environment } from "../../../../../apps/ogreg-public/src/environments/environment";

@Injectable({
  providedIn: 'root'
})
export class LoginService {
  private loggedInKey = 'loggedIn'
  private ogregToken = 'ogregToken';
  private person = 'person';

  private logOutSource = new BehaviorSubject('');
  logOutEvent = this.logOutSource.asObservable();

  constructor(private http: HttpClient) { }

  isLoggedIn() {
    return sessionStorage.getItem(this.loggedInKey) ?? false;
  }

  checkLoggedIn() {
    this.http.get(environment.personRegisterUrl + 'persons/me').subscribe(obs => {
        sessionStorage.setItem(this.loggedInKey, 'true');
      }, error => {
         sessionStorage.removeItem(this.loggedInKey);
      }
    );
  }

  logIn(token?: string) {
    sessionStorage.setItem(this.loggedInKey, 'true');
  }

  setOgregToken(token: string) {
    sessionStorage.setItem(this.ogregToken, token);
  }

  logOut() {
    sessionStorage.removeItem(this.loggedInKey);
    sessionStorage.removeItem(this.person);
    sessionStorage.removeItem(this.ogregToken);
    sessionStorage.removeItem("access_token");
    sessionStorage.removeItem("access_token_stored_at");
    sessionStorage.removeItem("expires_at");
    this.logOutSource.next('');
  }

  setPersonIdInSessionStorage(token: string) {
    if (token) {
      sessionStorage.setItem(this.person, JSON.stringify(this.getDecodedPayload(token)?.person));
    }
  }

  getDecodedPayload(token: string) {
    const payload = token.split('.')[1];
    return JSON.parse(Base64.decode(payload));
  }

  getPerson() {
    const personString = sessionStorage.getItem(this.person);
    if (personString) {
      return JSON.parse(personString);
    }
  }
}
