import {Component, HostListener, OnInit} from '@angular/core';
import {TranslateModule} from '@ngx-translate/core';
import {LoginService} from '@ogreg/ogreg-login';
import {NgIf} from '@angular/common';
import {MailVerificationInformationDto, PersonDto, UpsertEnrichmentDto} from "@ogreg/shared";
import {AuthService} from '../services/http-services/auth.service';
import {HelpComponent} from '../help/help.component';
import {
    UserGuidancePersonRegisterComponent
} from './user-guidance-person-register/user-guidance-person-register.component';
import {PersonalDataComponent} from './personal-data/personal-data.component';
import {MatSlideToggle} from '@angular/material/slide-toggle';
import {ContactDetailsComponent} from './contact-details/contact-details.component';
import {EmailVerificationService} from "../services/http-services/email-verification.service";
import {environment} from "../../environments/environment";
import {HttpClient} from "@angular/common/http";
import {BreadcrumbComponent, BreadCrumbOptions} from "../shared/components/ogp-breadcrumb/ogp-breadcrumb.component";
import {EnrichmentService} from "../services/http-services/enrichment.service";

@Component({
    selector: 'ogp-person-register',
    templateUrl: './person-register.component.html',
    styleUrl: './person-register.component.scss',
    standalone: true,
    imports: [
        NgIf,
        TranslateModule,
        HelpComponent,
        UserGuidancePersonRegisterComponent,
        PersonalDataComponent,
        MatSlideToggle,
        ContactDetailsComponent,
        BreadcrumbComponent
    ]
})
export class PersonRegisterComponent implements OnInit {
    currentEnrichment: UpsertEnrichmentDto | null = null;
    currentVerification: MailVerificationInformationDto | undefined;
    eIamPerson: PersonDto | null = null;
    userGuideOpenMd = false;
    userGuideOpenLg = true;
    isLargeScreen: boolean;
    public breadcrumbOptions!: BreadCrumbOptions;

    constructor(
        private readonly loginService: LoginService,
        private readonly enrichmentService: EnrichmentService,
        private readonly authService: AuthService,
        private readonly http: HttpClient,
        private readonly emailVerificationService: EmailVerificationService
    ) {
        this.isLargeScreen = window.innerWidth > 905;
    }

    ngOnInit() {
        this.getCurrentPerson();
        this.fetchMailVerificationInformation();
        this.breadcrumbOptions = {
            pathTranslationKey01: "homePage.title",
            pathTranslationKeyKey02: "personRegister.title",
            pathRouterLink01: "home",
        }
    }


    @HostListener('window:resize', ['$event'])
    onResize(event: { target: { innerWidth: number; }; }) {
        // Update the boolean variable when window is resized
        this.isLargeScreen = event.target.innerWidth > 905;
        if (this.isLargeScreen) {
            this.userGuideOpenMd = false;
        }
    }

    onEnrichmentEdited(enrichmentDto: UpsertEnrichmentDto) {
        this.enrichmentService.updateEnrichment(enrichmentDto).subscribe({
            next: () => {
                this.authService.exchangeToken().subscribe(token => {
                    if (token.token) {
                        this.loginService.setOgregToken(token.token);
                    }
                });

                if (enrichmentDto.email !== this.currentEnrichment?.email) {
                    this.currentVerification = {
                        needsMailVerification: true,
                        forMail: enrichmentDto.email
                    };
                }

                this.currentEnrichment = enrichmentDto;
            },
            error: (error) => {
                console.error(error);
            }
        });
    }

    onEnrichmentCreated(enrichmentDto: UpsertEnrichmentDto) {
        this.enrichmentService.saveEnrichment(enrichmentDto).subscribe({
            next: () => {
                this.authService.exchangeToken().subscribe(token => {
                    if (token.token) {
                        this.loginService.setOgregToken(token.token);
                    }
                });

                this.currentVerification = {
                    needsMailVerification: true,
                    forMail: enrichmentDto.email
                };

                this.currentEnrichment = enrichmentDto;
            },
            error: (error) => {
                console.error(error);
            }
        });
    }

    fetchMailVerificationInformation() {
        this.emailVerificationService.getCurrentVerification().subscribe((mailVerificationInformation) => {
            this.currentVerification = mailVerificationInformation;
        })
    }

    getCurrentPerson() {

        const getCurrentEiamPerson = () => {
            this.http.get<PersonDto>(environment.personRegisterUrl + 'persons/me/eiam').subscribe({
                next: (eIamObs: PersonDto | null) => {
                    this.eIamPerson = eIamObs;
                    this.currentEnrichment = null;
                },
                error: (err) => {
                    console.error('Error fetching eIAM person:', err);
                }
            });
        }

        this.http.get<PersonDto>(environment.personRegisterUrl + "persons/me").subscribe({
            next: (obs: PersonDto) => {
                if (!obs || !obs.socialSecurityNumber) {
                    getCurrentEiamPerson();
                } else {
                    this.eIamPerson = obs;
                    this.currentEnrichment = this.getEnrichmentFromCurrentPerson();
                }
            },
            error: () => {
                getCurrentEiamPerson();
            }
        });
    }

    getEnrichmentFromCurrentPerson(): UpsertEnrichmentDto {
        return {
            email: this.eIamPerson?.email ?? this.eIamPerson?.secondaryEmail,
            telephoneNumber: this.eIamPerson?.telephoneNumber,
            mobileNumber: this.eIamPerson?.mobileNumber,
            acceptedLegalText: this.eIamPerson?.acceptedLegalText,
        }
    }

    toggleUserGuideMd() {
        this.userGuideOpenMd = (!this.userGuideOpenMd);
    }

    toggleUserGuideLg() {
        this.userGuideOpenLg = (!this.userGuideOpenLg);
    }

    onEmailReset(success: boolean) {
        if (success) {
            this.getCurrentPerson();
            this.currentVerification = undefined;
        }
    }
}
