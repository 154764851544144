<ogp-card-content [cardTitle]="'personRegister.personalData.title'">
    <div>
        {{ "personRegister.personalData.subtitle" | translate }}
        «<a href="person-register#contact-details">{{ "personRegister.contactDetails.title" | translate }}</a>».
    </div>
    <ng-container *ngIf="eIamPerson">
        <div class="container disabled-color">
            <div class="row">
                <mat-label class="col-sm-6"
                           data-cy="lastName">
                    <div class="label">{{ "personRegister.contactDetails.person.lastName" | translate }}</div>
                    {{ eIamPerson.lastName }}
                </mat-label>
                <mat-label class="col-sm-6"
                           data-cy="firstName">
                    <div class="label">{{ "personRegister.contactDetails.person.firstName" | translate }}</div>
                    {{ eIamPerson.firstName }}
                </mat-label>
            </div>
            <div class="row">
                <mat-label class="col-sm-6"
                           data-cy="birthDate">
                    <div class="label">{{ "personRegister.contactDetails.person.birthDay" | translate }}</div>
                    {{ eIamPerson.birthDate | date: 'dd.MM.yyyy' }}
                </mat-label>
                <mat-label class="col-sm-6"
                           data-cy="placeOfBirth">
                    <div class="label">{{ "personRegister.contactDetails.person.placeOfBirth" | translate }}</div>
                    {{ eIamPerson.placeOfBirth }}
                </mat-label>
            </div>
            <div class="row">
                <mat-label class="col-sm-6"
                           data-cy="homeTown">
                    <div class="label">{{ "personRegister.contactDetails.person.homeTown" | translate }}</div>
                    {{ eIamPerson.homeTown }}
                </mat-label>
                <mat-label class="col-sm-6"
                           data-cy="socialSecurityNumber">
                    <div class="label">{{ "personRegister.contactDetails.person.socialSecurityNumber" | translate }}</div>
                    {{ eIamPerson.socialSecurityNumber }}
                </mat-label>
            </div>
        </div>
        <p style="padding-top: 40px;">{{ "personRegister.personalData.changeDataText" | translate }}
            <br/>
            <a href="https://www.bit.admin.ch/bit/de/home.html" icon="right">
                {{ "personRegister.personalData.personalDataLinkText" | translate }}
            </a>
        </p>
    </ng-container>
</ogp-card-content>
