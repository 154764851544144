
<h1> Debug Login </h1>

<input matInput [(ngModel)]="id" data-cy="id" >
<input matInput [(ngModel)]="firstName" data-cy="firstName" >
<input matInput [(ngModel)]="lastName" data-cy="lastName">
<input matInput [(ngModel)]="birthDate" data-cy="birthDate">
<input matInput [(ngModel)]="nationality" data-cy="nationality">
<input matInput [(ngModel)]="placeOfBirth" data-cy="placeOfBirth">
<input matInput [(ngModel)]="homeTown" data-cy="homeTown">
<input matInput [(ngModel)]="socialSecurityNumber" data-cy="socialSecurityNumber">
<input matInput [(ngModel)]="gender" data-cy="gender">


<button (click)="login()" data-cy="login-button"> Login </button>


<!-- XML Placeholder Fields -->
<h3>ZAS</h3>
<input matInput [(ngModel)]="ahvn" data-cy="xmlAhvn">
<input matInput [(ngModel)]="xmlFirstName" data-cy="xmlFirstName" placeholder="XML First Name">
<input matInput [(ngModel)]="xmlOfficialName" data-cy="xmlOfficialName" placeholder="XML Official Name">
<input matInput [(ngModel)]="xmlOriginalName" data-cy="xmlOriginalName" placeholder="XML Original Name">
<input matInput [(ngModel)]="xmlSex" data-cy="xmlSex" placeholder="XML Gender">
<input matInput [(ngModel)]="xmlBirthDate" data-cy="xmlBirthDate" placeholder="XML Birth Date">
<input matInput [(ngModel)]="xmlBirthPlace" data-cy="xmlBirthPlace" placeholder="XML Birth Place">

<button (click)="regenerateAhv()" data-cy="regenerate-ahv-button">Regenerate AHVN</button>
<button (click)="loginWithXML()" data-cy="login-with-xml-button"> Create in ZAS </button>

<div>
  Gender: 1 = Male, 2 = Female, 3 = Unspecified.
  Birthday-Format: YYYY-MM-DD
</div>