import {Component, EventEmitter, OnInit, Output} from "@angular/core";
import {getDonorOptionBackgroundColor, NavigationStepData, VolitionService} from "../../volition.service";
import {OgpCardContentComponent} from "../../../shared/components/ogp-card-content/ogp-card-content.component";
import {TranslateModule} from "@ngx-translate/core";
import {
    CardOption,
    OgpCardSelectionComponent
} from "../../../shared/components/ogp-card-selection/ogp-card-selection.component";
import {MatButton} from "@angular/material/button";
import {ObButtonDirective} from "@oblique/oblique";

@Component({
    selector: "ogp-view-confirmation-step",
    templateUrl: "view-confirmation-step.html",
    standalone: true,
    imports: [
        OgpCardContentComponent,
        TranslateModule,
        OgpCardSelectionComponent,
        MatButton,
        ObButtonDirective
    ]
})

export class ViewConfirmationStepComponent implements OnInit {
    @Output() public activeStepChanged: EventEmitter<NavigationStepData> = new EventEmitter<NavigationStepData>();
    public donorCardOption!: CardOption;

    constructor(private volitionService: VolitionService) {
    }

    public ngOnInit(): void {
        const donationData = this.volitionService.getDonationDataValue();
        const decision = donationData?.volition?.decision;
        if (decision != null) {
            this.donorCardOption = {
                cardId: decision.toLowerCase(),
                cardTitleKey: donationData?.person?.lastName + ", " + donationData?.person?.firstName,
                cardInformationKey: `volitions.decisionStep.${decision}.information`,
                dateLabel: `volitions.viewTemplateStep.personRegisterData.creationDate`,
                date: donationData?.volition?.creationDate ?? new Date().toString(),
                headerIconNameKey: `volitions.decisionStep.${decision}.headerIconName`,
                cardHoverColor: "neutral",
                cardBackgroundColor: getDonorOptionBackgroundColor(decision),
                headerIconAlt: `volitions.decisionStep.${decision}.headerIconAlt`,
                optionKey: decision

            };
        } else {
            throw new Error("selected decision value not found");
        }

    }

    public onOpenVolitionRegistryEntry(): void {
        this.activeStepChanged.emit({
            activeStep: "STORAGE_STEP",
            activeSubStep: "viewFinalRegistryEntry",
            lastCompletedStep: "viewConfirmationStep"
        });
    }

    public onConfirmationCardEnterKeyPressed(decision: string): void {
        if (decision != null) {
            this.onOpenVolitionRegistryEntry()
        }
    }

    public onDownloadPDF(): void {
        // TODO: Add functionality to download registry entry as pdf
    }
}
