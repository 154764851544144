import {Component, OnDestroy, OnInit} from '@angular/core';
import {VolitionDto} from '../generated/model/modelsShared';
import {VolitionsService} from '../services/http-services/volitions.service';
import {Observable, switchMap, tap} from 'rxjs';
import {ObBreadcrumbModule, ObNotificationService} from '@oblique/oblique';
import {TranslateModule, TranslatePipe} from '@ngx-translate/core';
import {NgClass, NgIf} from '@angular/common';
import {BreadcrumbComponent, BreadCrumbOptions} from "../shared/components/ogp-breadcrumb/ogp-breadcrumb.component";
import {BodyPartDto} from "../generated/model/modelsQuery";
import {OgpStepperComponent} from "../shared/components/ogp-stepper/ogp-stepper.component";
import {OgpStepperService} from "../shared/components/ogp-stepper/ogp-stepper.service";
import {HelpComponent} from "../help/help.component";
import {FaqItem, OgpFaqSectionComponent} from "../shared/components/ogp-faq-section/ogp-faq-section.component";
import {DonorOptionStepComponent} from "./steps/donor-option-step/donor-option-step.component";
import {MyChoiceStepComponent} from "./steps/my-choice-step/my-choice-step.component";
import {MainStepEnum, NavigationStepData, OrganAndTissueDonationEntry, VolitionService} from "./volition.service";
import {ViewTemplateStepComponent} from "./steps/view-template-step/view-template-step.component";
import {ViewConfirmationStepComponent} from "./steps/view-confirmation-step/view-confirmation-step";
import {MatCardModule} from "@angular/material/card";
import {ViewFinalRegistryEntryComponent} from "./steps/view-final-registry-entry/view-final-registry-entry.component";

@Component({
    selector: 'ogp-volition',
    templateUrl: './volition.component.html',
    styleUrls: ['./volition.component.scss'],
    standalone: true,
    imports: [
        TranslateModule,
        ObBreadcrumbModule,
        BreadcrumbComponent,
        OgpStepperComponent,
        HelpComponent,
        OgpFaqSectionComponent,
        NgIf,
        DonorOptionStepComponent,
        MyChoiceStepComponent,
        ViewTemplateStepComponent,
        ViewConfirmationStepComponent,
        MatCardModule,
        ViewFinalRegistryEntryComponent,
        NgClass
    ]
})
export class VolitionComponent implements OnInit, OnDestroy {
    protected breadcrumbOptions!: BreadCrumbOptions;
    protected volition$!: Observable<VolitionDto>;
    protected bodyParts$!: Observable<BodyPartDto[]>;
    protected stepData!: NavigationStepData;
    public stepperNames: string[] = ['Entscheid', 'Bestätigung', 'Ablage'];
    protected currentStep = 0;
    protected faqItems!: FaqItem[];
    protected pageTitle!: string;
    protected pageInformation!: string;

    constructor(
        private readonly volitionsService: VolitionsService,
        private readonly volitionService: VolitionService,
        private readonly notificationService: ObNotificationService,
        private readonly translationPipe: TranslatePipe,
        private stepperService: OgpStepperService
    ) {
        // Subscribe to the current step to keep track in the parent component
        this.stepperService.currentStep$.subscribe(step => {
            this.currentStep = step;
        });
    }

    public async ngOnInit(): Promise<void> {
        this.breadcrumbOptions = {
            pathTranslationKey01: "homePage.title",
            pathTranslationKeyKey02: "volitions.title",
            pathRouterLink01: "home",
        }
        this.volitionService.getDonationData().subscribe((donationData) => {
            if (donationData) {
                this.setActiveStep(donationData);
            }
        });
        // Initialize donation data
        this.volitionService.initDonationData();
        this.faqItems = ["q1", "q2", "q3", "q4", "q5", "q6", "q7",].map(key => {
            return {
                questionKey: `volitions.faq.${key}.question`,
                answerKey: `volitions.faq.${key}.answer`
            }
        });
        //Albert
        this.volition$ = this.getVolition();
        this.bodyParts$ = this.volitionsService.getBodyParts();
    }

    private setActiveStep(donationData: OrganAndTissueDonationEntry): void {
        this.stepData = donationData.volition?.isVolitionCompleted
            ? {
                activeStep: 'STORAGE_STEP',
                activeSubStep: 'viewConfirmationStep',
                lastCompletedStep: 'viewTemplateStep',
            }
            : {
                activeStep: 'DECISION_STEP',
                activeSubStep: 'donorOptionStep',
                lastCompletedStep: null,
            };
        this.updatePageHeaderContent(false);
    }

    public ngOnDestroy(): void {
        this.stepperService.resetStepper();
    }

    public handleActiveStepChanged(navStepData: NavigationStepData): void {
        this.stepData = navStepData;
        if (this.stepperService.getCurrentMainStep() < MainStepEnum[this.stepData.activeStep]) {
            const delay: number = this.stepData.activeStep === "STORAGE_STEP" ? 1000 : 0
            this.stepperService.getNextMainStep(delay);
        } else if (this.stepperService.getCurrentMainStep() > MainStepEnum[this.stepData.activeStep]) {
            this.stepperService.getPreviousMainStep();
        }
        this.updatePageHeaderContent(this.stepData?.action === 'created');
    }

    public updatePageHeaderContent(isVolitionCreatedOrModified: boolean): void {
        if (this.stepData.activeSubStep === "viewTemplateStep" || this.stepData.activeSubStep === "viewFinalRegistryEntry" || this.stepData.activeSubStep === "viewConfirmationStep") {
            if (isVolitionCreatedOrModified) {
                this.pageTitle = "volitions." + this.stepData.activeSubStep + ".successPageTitle"
                this.pageInformation = "volitions." + this.stepData.activeSubStep + ".successPageInformation"
            } else {
                this.pageTitle = "volitions." + this.stepData.activeSubStep + ".pageTitle"
                this.pageInformation = "volitions." + this.stepData.activeSubStep + ".pageInformation"
            }
        } else {
            this.pageTitle = "volitions.title";
            this.pageInformation = "volitions.information";
        }
    }

    private getVolition(): Observable<VolitionDto> {
        return this.volitionsService.getVolition();
    }

    protected onVolitionChange(volitionDto: VolitionDto): void {
        if (!volitionDto.id) {
            this.volition$ = this.volitionsService.createVolition(volitionDto).pipe(
                switchMap(() => this.getVolition()),
                tap((volitionDto: VolitionDto) => {
                    if (volitionDto?.id) {
                        this.notificationService.success({
                                message: this.translationPipe.transform('volitions.volitionCreatedNotification'),
                                timeout: 20000
                            }
                        );
                    }
                })
            );
        } else {
            // @ts-expect-error refactor it
            this.volition$ = this.volitionsService.editVolition(volitionDto).pipe(
                switchMap(() => this.getVolition()),
                tap((volitionDto: VolitionDto) => {
                    if (volitionDto?.id) {
                        this.notificationService.success({
                                message: this.translationPipe.transform('volitions.volitionEditedNotification'),
                                timeout: 20000
                            }
                        );
                    }
                })
            );
        }
    }
}

